import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import TextImageRow from 'components/molecules/component-image-row';
import Navbar from 'components/organisms/navbar';

import Callout from 'components/callout';
import Layout from 'components/layout';
import ScheduleBlock from 'components/mindbody-online';
import PageMeta from 'components/page-meta/page-meta';
import LeadSubmitForm from 'components/lead-submit-form';

import { ContentBox, lowLine } from 'styles/boxes.css';
import {
  FocusH3Vincent,
  H3Vincent,
  H2Vincent,
  H2Black,
} from 'styles/headers.css';
import { navbarOffset } from 'styles/spacing.css';

export class AlwaysOnTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
    this.setSubmitTrue = this.setSubmitTrue.bind(this);
  }

  setSubmitTrue() {
    this.setState({ submitted: true });
  }

  render() {
    const { data } = this.props;

    const imageRow = data.imageRow.childImageSharp.fluid;
    const apiUrl = data.site.siteMetadata.apiUrl;
    const { campaign, directResponseAd } = this.props.data.gwxapi;
    const campaignFocus = campaign.sessionFocus;
    const pageTitle = 'Become Unstoppable Special | Gloveworx';
    const market = campaign.market == 'losAngeles' ? 'LA' : 'WTC';
    const price = campaign.market == 'losAngeles' ? '20' : '20';
    const pageDescription = `Get your first session in the ring at a special
    rate of just ${price}! Book your sessions at any of our Gloveworx
    LA or NY locations and take advantage of this offer. Are
    you ready to start your journey to becoming unstoppable?`;
    const imageComponent = (
      <Img fluid={imageRow} style={{ maxHeight: '100vh' }} />
    );
    const text =
      market == 'LA'
        ? 'Join us at Gloveworx'
        : `Join us at the Gloveworx ${market} Studio`;
    const calloutComponent =
      market === 'LA' ? null : (
        <Callout
          text={text}
          width={1}
          backgroundColor="gray"
          link="https://www.gloveworx.com/locations/world-trade-center/"
        />
      );

    const { templateType } = this.props.pageContext;
    return (
      <Layout>
        <PageMeta title={pageTitle} description={pageDescription} />
        <Navbar />
        <div css={navbarOffset}>
          <TextImageRow
            imageComponent={imageComponent}
            callout={calloutComponent}
          >
            <H2Vincent>WORLD CLASS</H2Vincent>
            <H2Vincent>TRAINING,</H2Vincent>
            <H2Vincent>INDIVIDUAL ATTENTION</H2Vincent>
            <hr css={lowLine('white', '2rem')} />
            <H3Vincent>Gloveworx sessions cap</H3Vincent>
            <H3Vincent>out at 8 people ensuring</H3Vincent>
            <H3Vincent>you’ll get personal attention</H3Vincent>
            <H3Vincent>and never get lost</H3Vincent>
            <H3Vincent> in the crowd.</H3Vincent>
            <hr css={lowLine('white', '2rem')} />
            <H3Vincent>Book a ${price} intro session now.</H3Vincent>
            {!this.state.submitted && templateType === true && (
              <LeadSubmitForm
                apiUrl={apiUrl}
                campaignId={campaign.id}
                sourceId={directResponseAd.id}
                sourceType="direct_response_ad"
                setSubmitTrue={this.setSubmitTrue}
                buttonType="drSubmit"
              />
            )}
          </TextImageRow>
        </div>
        <ContentBox>
          {(this.state.submitted || templateType === false) && (
            <React.Fragment>
              <H2Black>NOW GET IN THE RING</H2Black>
              <FocusH3Vincent style={{ fontWeight: 'normal' }}>
                BOOK THE SESSION YOU WANT TO ATTEND
              </FocusH3Vincent>
              {campaign.mindbodyOnlineWidgetId && this.state.submitted && (
                <ScheduleBlock
                  name={campaignFocus}
                  header=""
                  mboID={campaign.mindbodyOnlineWidgetId}
                  widgetType={campaign.mboWidgetType}
                  includeHr={false}
                />
              )}
            </React.Fragment>
          )}
        </ContentBox>
      </Layout>
    );
  }
}

AlwaysOnTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        apiUrl: PropTypes.string,
      }),
    }),
    imageRow: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    gwxapi: PropTypes.shape({
      friend: PropTypes.object,
      campaign: PropTypes.object,
      directResponseAd: PropTypes.object,
      lead: PropTypes.object,
    }),
  }),
  pageContext: PropTypes.object,
};

export default AlwaysOnTemplate;

export const drQuery = graphql`
  query(
    $adId: String!
    $leadId: String!
    $campaignId: String!
    $templateType: Boolean!
  ) {
    site {
      siteMetadata {
        apiUrl
      }
    }
    imageRow: file(name: { eq: "always-on-vertical.original" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gwxapi @include(if: $templateType) {
      directResponseAd(id: $adId) {
        id
      }
    }
    gwxapi @skip(if: $templateType) {
      lead(id: $leadId) {
        id
      }
    }
    gwxapi {
      campaign(id: $campaignId) {
        id
        data
        mindbodyOnlineWidgetId
        mboWidgetType
        sessionFocus
        spotsLeft
        market
      }
    }
  }
`;
