import React from 'react';
import PropTypes from 'prop-types';

import { css } from '@emotion/core';
import { mq } from 'styles/breakpoints';

import { textColor } from 'styles/text.css';

/* TODO:
 * Update `background-color` to be a prop for future usage,
 * or refactor in another way for better extensibility of this component.
 */
export const tiRow = css`
  background-color: #be000c;
  height: 100%;
  ${textColor.white};
  padding-bottom: 2rem;
  text-align: center;
  ${mq.tablet} {
    padding-bottom: 0px;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export const tiRowImage = css`
  ${mq.tablet} {
    width: 60%;
  }
`;

export const tiRowText = css`
  ${mq.tablet} {
    width: 40%;
  }
`;

export const tiExtra = css`
  display: block;
  ${mq.tablet} {
    display: none;
  }
`;

export const tiExtraDesktop = css`
  display: none;
  ${mq.tablet} {
    display: block;
  }
`;

const TextImageRow = ({ imageComponent, children, callout }) => (
  <React.Fragment>
    <div css={tiRow}>
      <div css={tiRowImage}>{imageComponent}</div>
      <div css={tiExtra}>{callout}</div>
      <div css={tiRowText}>
        <div>{children}</div>
      </div>
    </div>
    <div css={tiExtraDesktop}>{callout}</div>
  </React.Fragment>
);

TextImageRow.propTypes = {
  imageComponent: PropTypes.node,
  children: PropTypes.node,
  callout: PropTypes.node,
};

export default TextImageRow;
