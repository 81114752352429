import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'universal-cookie';

export const isBrowser = typeof window !== 'undefined';

export async function setLeadId(leadId, apiUrl) {
  if (!isBrowser) {
    return;
  }
  try {
    /**
     * Start getFingerprint() first since it is the slowest process
     * then send it to the API to have it ready in case the cookie or local storage doesn't
     * get the time to run or the users are using inApp browsers (mobile devices)
     */

    const fingerprint = await getFingerprint();

    if (typeof leadId !== 'undefined') {
      axios.patch(`${apiUrl}/v1/leads/${leadId}/`, {
        fingerprint: fingerprint,
        lid: leadId,
      });
    }
  } catch (error) {
    // eslint-disable-next-line
    console.warn(`Not able to set lead: ${error}`);
  }
  /**
   * After the fingerprint is triggered then we continue to set a cookie
   * this approach is faster and is persistent in desktop
   * the cookie will last for a year
   */
  const cookies = new Cookies();
  const date = new Date();
  const expires = new Date(
    date.getFullYear() + 1,
    date.getMonth(),
    date.getDay()
  );

  const options = {
    expires: expires,
    path: '/',
    domain: process.env.COOKIE_DOMAIN,
  };

  cookies.set('lid', leadId, options);

  /**
   * As a third method of security localStorage is being used to track the lid
   * it is also fast but is not as reliable as the cookie
   */
  localStorage.setItem('lid', leadId);
  return true;
}

export async function getFingerprint() {
  // The excludes need to be set to true in order to be excluded.
  const options = {
    excludes: {
      fonts: true,
      sessionStorage: true,
      localStorage: true,
      fontsFlash: true,
      enumerateDevices: true,
      userAgent: true,
    },
  };
  const fingerprintValues = await Fingerprint2.getPromise(options);
  const values = fingerprintValues.map(component => {
    return component.value;
  });

  return Fingerprint2.x64hash128(values.join(''), 31);
}

export async function convertLead(apiUrl) {
  if (!isBrowser) {
    return;
  }

  const fingerprint = await getFingerprint();
  /**
   * first we attempt to retrieve the cookie, if there is one then we patch the API
   * to set the `is_converted: true`
   */
  const cookies = new Cookies();
  let lidStorage;
  let lid = await cookies.get('lid');

  if (typeof lid === 'undefined') {
    // if there was no cookie with `lid` set then we try to retrieve it from localStorage
    lidStorage = localStorage.getItem('lid');
  } else {
    lid = lid.lid;
  }

  if (typeof lidStorage !== 'undefined') {
    // LocalStorage had a `lid` and cookie didn't
    lid = lidStorage;
  }

  try {
    /**
     * If there is no cookie, we check for `localStorage`
     * using `getItem`, which will return `null` if
     * nothing is found.
     *
     * In the case that the `lid` is not defined in either
     * the cookies or localStorage, `lid` will be equal
     * to `null`.
     */
    if (lid !== null) {
      axios.patch(`${apiUrl}/v1/leads/${lid}/`, {
        is_converted: true,
      });
      return true;
    }

    /**
     * If we don't have a cookie present then we try to retrieve the fingerprint
     * then we send it to the API to make sure the `is_converted: true` is set
     *
     * This document was helpful for the fingerprint:
     * https://www.doc.ic.ac.uk/~livshits/papers/theses/john_oliver.pdf
     */
    if (typeof fingerprint !== 'undefined') {
      axios.post(`${apiUrl}/v1/leads/convert/`, {
        fingerprint: fingerprint,
      });
    }
  } catch (error) {
    // eslint-disable-next-line
    console.warn(`Not able to set lead: ${error}`);
  }
}
