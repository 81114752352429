import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { Box } from '@rebass/grid';

import { mq } from 'styles/breakpoints';
import { H4, H3 } from 'styles/headers.css';
import { bgColor, textColor, textFormat } from 'styles/text.css';

const callout = backgroundColor => {
  return css`
    ${bgColor[backgroundColor]};
    ${textColor.white};
    ${textFormat.alignCenter};
    ${textFormat.headerBold};
    ${textFormat.upperCase};
    position: relative;
    padding: 0.5rem;
    margin-top: -0.5rem;
    margin-bottom: 0;
    z-index: 1;
    font-size: 1.25rem;

    ${mq.tablet} {
      font-size: 2rem;
      padding: 1rem;
      margin-top: -1rem;
    }
  `;
};

const spanMargin = css`
  margin-left: 0.25rem;
  ${mq.tablet} {
    margin-left: 2.5rem;
  }
`;

const Callout = ({
  text,
  extraText,
  width = 0.8,
  campaign = 'group',
  backgroundColor = 'red',
  link = null,
}) => {
  const content =
    campaign == 'private' ? (
      <H3 css={callout(backgroundColor)}>
        <span>{text}</span>
        {extraText && <span css={spanMargin}>{extraText}</span>}
      </H3>
    ) : (
      <H4 css={callout(backgroundColor)}>
        <span>{text}</span>
        {extraText && <span css={spanMargin}>{extraText}</span>}
      </H4>
    );

  return (
    <Box width={width} m="auto">
      {link === null ? (
        content
      ) : (
        <a href={link} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      )}
    </Box>
  );
};

Callout.propTypes = {
  text: PropTypes.string.isRequired,
  extraText: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  campaign: PropTypes.string,
  backgroundColor: PropTypes.string,
  link: PropTypes.string,
};

export default Callout;
