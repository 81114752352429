import React from 'react';
import { slide as Menu } from 'react-burger-menu';

import { css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import { mq } from 'styles/breakpoints';

const styles = ({ theme = 'default' }) => {
  return {
    bmBurgerButton: {
      position: 'fixed',
      width: '1.5rem',
      height: '1.25rem',
      left: '2rem',
      margin: '1.5rem auto',
    },
    bmBurgerBars: {
      background: theme === 'default' ? 'black' : 'white',
      height: '4%',
    },
    bmCrossButton: {
      height: '2rem',
      width: '2rem',
    },
    bmCross: {
      background: 'black',
    },
    bmMenuWrap: {
      position: 'fixed',
      height: 'auto',
    },
    bmMenu: {
      background: 'white',
      padding: '2.5em 1.5em 0',
      fontSize: '1.15em',
    },

    bmItemList: {
      color: '#b8b7ad',
      padding: '0.8em',
    },
    bmItem: {
      display: 'flex',
      flexDirection: 'column',
      outline: 'none',
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)',
    },
  };
};

const burgerWrapper = css`
  ${mq.tablet} {
    display: none;
  }
`;

const logotext = css`
  max-width: 7rem;
  margin-top: -2rem;
  margin-bottom: 1rem;
`;

/**
 * All the animations are handled internally by the component.
 * However, the visual styles (colors, fonts etc.) are not, and
 * need to be supplied, either with CSS or with a JavaScript object
 * passed as the styles prop.
 */
const Hamburger = ({ menuItems, isToggleOn, theme }) => (
  <StaticQuery
    query={graphql`
      query {
        gwxLogoText: file(name: { eq: "gwx-logo-text.original" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <div css={burgerWrapper}>
        <Menu isOpen={isToggleOn} styles={styles({ theme: theme })}>
          <Img fluid={data.gwxLogoText.childImageSharp.fluid} css={logotext} />
          <div>{menuItems}</div>
        </Menu>
      </div>
    )}
  />
);

Hamburger.propTypes = {
  menuItems: PropTypes.array,
  isToggleOn: PropTypes.bool.isRequired,
  theme: PropTypes.string,
};

export default Hamburger;
