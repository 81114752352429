import { css } from '@emotion/core';

import { mq } from './breakpoints';
import { componentHeights, spacing } from './variables.css';

export const standardMargin = css`
  margin: ${spacing.margin.mobile};

  ${mq.tablet} {
    margin: ${spacing.margin.tablet};
  }

  ${mq.desktop} {
    margin: ${spacing.margin.desktop};
  }

  ${mq.widescreen} {
    margin: ${spacing.margin.widescreen};
  }
`;

export const standardMarginMixin = positionsString => {
  let marginStyling = '';
  const positions = positionsString.split(', ');
  positions.forEach(position => {
    marginStyling.concat(`\n
      margin-${position}: ${spacing.margin.mobile};

      ${mq.tablet} {
        margin-${position}: ${spacing.margin.tablet};
      }

      ${mq.desktop} {
        margin-${position}: ${spacing.margin.desktop};
      }

      ${mq.widescreen} {
        margin-${position}: ${spacing.margin.widescreen};
      }
  `);
  });
  return css`
    ${marginStyling}
  `;
};

export const standardPadding = css`
  padding: ${spacing.padding.mobile};

  ${mq.tablet} {
    padding: ${spacing.padding.tablet};
  }

  ${mq.desktop} {
    padding: ${spacing.padding.desktop};
  }

  ${mq.widescreen} {
    padding: ${spacing.padding.widescreen};
  }
`;

export const standardPaddingMixin = positionsString => {
  let paddingStyling = '';
  const positions = positionsString.split(', ');
  positions.forEach(position => {
    paddingStyling.concat(`\n
      padding-${position}: ${spacing.padding.mobile};

      ${mq.tablet} {
        padding-${position}: ${spacing.padding.tablet};
      }

      ${mq.desktop} {
        padding-${position}: ${spacing.padding.desktop};
      }

      ${mq.widescreen} {
        padding-${position}: ${spacing.padding.widescreen};
      }
  `);
  });
  return css`
    ${paddingStyling}
  `;
};

export const navbarOffset = css`
  margin-top: ${componentHeights.navbar.main};

  ${mq.desktop} {
    margin-top: ${componentHeights.navbar.large};
  }
`;
