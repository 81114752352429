import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { animateScroll } from 'react-scroll';

import { getFingerprint, setLeadId } from 'helpers/leadId';

//import { buttonHeavy } from 'styles/button.css';
import { inputField, submitButton } from 'styles/forms.css';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Must enter email'),
});

function scrollToBottom() {
  animateScroll.scrollToBottom();
}

class LeadSubmitForm extends React.Component {
  /*
   * Adapted from `ReferralForm`
   */
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.fingerprint = await getFingerprint();
  }

  render() {
    const {
      apiUrl,
      campaignId,
      sourceId,
      sourceType,
      setSubmitTrue,
      buttonType,
    } = this.props;
    return (
      <Formik
        initialValues={{
          campaign: campaignId,
          [sourceType]: sourceId,
          email: '',
          submitted: false,
        }}
        validationSchema={schema}
        onSubmit={(values, { setFieldValue, setSubmitting }) => {
          let data = Array();
          data.push({
            campaign: values.campaign,
            [sourceType]: values[sourceType],
            email: values.email,
            fingerprint: this.fingerprint,
          });
          return fetch(`${apiUrl}/v1/leads/`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          })
            .then(response => {
              setSubmitting(false);
              return response.json();
            })
            .then(function(json) {
              json.forEach(lead => {
                setLeadId(lead.id, apiUrl);
              });
              setFieldValue('submitted', true);
              setSubmitTrue();
            });
        }}
      >
        {({ values, isValid }) => (
          <Form>
            <div style={{ position: 'relative' }}>
              <Field
                css={
                  buttonType != 'drSubmit' ? inputField() : inputField('white')
                }
                id="email"
                type="email"
                name="email"
                placeholder="Email Address"
                disabled={values.submitted}
              />
              {/* eslint-disable-next-line */}
              <label htmlFor="email" aria-label="email" aria-required="true" />
            </div>
            <div>
              <button
                type="submit"
                css={
                  buttonType != 'drSubmit'
                    ? submitButton({})
                    : submitButton({
                        color: 'red',
                        colord: 'white',
                        backgroundColor: 'white',
                        backgroundColord: 'transparent',
                        borderColor: 'white',
                        drSubmit: 'drSubmit',
                      })
                }
                onClick={
                  buttonType != 'drSubmit'
                    ? () => {}
                    : () => {
                        scrollToBottom();
                      }
                }
                disabled={!isValid}
              >
                Reserve My Spot
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

LeadSubmitForm.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
  sourceType: PropTypes.string.isRequired,
  campaignId: PropTypes.string.isRequired,
  setSubmitTrue: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
};

export default LeadSubmitForm;
