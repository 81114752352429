import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ContentHr } from 'styles/boxes.css';
import { FocusH3 } from 'styles/headers.css';

const sessionFilterId = 'session-filter-style';
const coachFilterId = 'coach-filter-style';

const setFilterStyles = (filterAttribute, filterValue, filterId) => {
  // Global stylesheet creation courtesy:
  // https://stackoverflow.com/questions/15493965/define-global-css-classes-using-javascript-or-jquery
  const style = document.createElement('style');
  const styleText = `
    .bw-header__filter-link { display: none !important }
    .bw-session:not([${filterAttribute}="${filterValue}"]) { display: none !important }
  `;

  style.type = 'text/css';
  style.id = filterId;

  if (style.styleSheet) {
    style.styleSheet.cssText = styleText;
  } else {
    style.appendChild(document.createTextNode(styleText));
  }

  document.getElementsByTagName('head')[0].appendChild(style);
};

class ScheduleBlock extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    header: PropTypes.string.isRequired,
    mboID: PropTypes.string.isRequired,
    widgetType: PropTypes.string.isRequired,
    description: PropTypes.string,
    coachFilter: PropTypes.string,
    sessionFilter: PropTypes.string,
    includeHr: PropTypes.bool,
  };

  componentDidMount() {
    const { name, mboID, widgetType, coachFilter, sessionFilter } = this.props;

    // document must be checked for or an error will occur in server-side rendering
    if (document) {
      // The healcode-widget element and the script have to be manually added to make sure react doesn't
      // mistake them for part of its own update lifecycle
      const mboWidget = document.getElementById(`${name}-mbo-widget`);
      mboWidget.innerHTML = `<healcode-widget data-type="${widgetType}" data-widget-partner="object" data-widget-id="${mboID}" data-widget-version="1" id="mbo-widget"></healcode-widget>`;
      const mboScript = document.createElement('script');
      mboScript.setAttribute(
        'src',
        'https://widgets.healcode.com/javascripts/healcode.js'
      );
      mboScript.setAttribute('type', 'text/javascript');
      mboScript.setAttribute('id', `${name}-mbo-script`);
      mboWidget.appendChild(mboScript);
    }

    // if sessionFilter or coachFilter is passed in, we want to hide all rows that don't match
    // the passed in value
    if (coachFilter) {
      setFilterStyles('data-bw-widget-trainer', coachFilter, coachFilterId);
    }

    if (sessionFilter) {
      setFilterStyles(
        'data-bw-widget-mbo-class-name',
        sessionFilter.replace(/-/g, '_'),
        sessionFilterId
      );
    }
  }

  componentWillUnmount() {
    const { name } = this.props;

    // healcode-widget element must be cleaned out so appropriate listeners are detached
    // script must be detached so the init part of the script will be run again when this element is re-loaded
    const mboEl = document.getElementById(`${name}-mbo-widget`);
    const mboScript = document.getElementById(`${name}-mbo-script`);

    mboEl.parentNode.removeChild(mboEl);
    mboScript.parentNode.removeChild(mboScript);

    // remove filter style tags so future schedule widgets aren't affected
    const coachFilterTag = document.getElementById(coachFilterId);
    const sessionFilterTag = document.getElementById(sessionFilterId);

    if (coachFilterTag) {
      coachFilterTag.parentNode.removeChild(coachFilterTag);
    }

    if (sessionFilterTag) {
      sessionFilterTag.parentNode.removeChild(sessionFilterTag);
    }
  }

  render() {
    const { header, name, description, includeHr } = this.props;
    const contentHeader = includeHr ? <ContentHr /> : '';
    return (
      <div className={`mbo-schedule-block ${name}-schedule-block`}>
        {contentHeader}
        <FocusH3 id={`${name}-mbo-schedule`} name="mbo-schedule" thin="none">
          {header}
        </FocusH3>
        <br />
        {description && (
          <p className="mbo-schedule-simple-text-content">{description}</p>
        )}
        <div className="gloveworx-mbo-widget" id={`${name}-mbo-widget`} />
      </div>
    );
  }
}

export default ScheduleBlock;
