import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import { css } from '@emotion/core';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

import Logo from 'components/atoms/logo';

import { mq } from 'styles/breakpoints';
import { standardPaddingMixin } from 'styles/spacing.css';
import { bgColor, menuText, textColor } from 'styles/text.css';
import { spacing } from 'styles/variables.css';
import Hamburger from 'components/molecules/hamburger/hamburger';

const navbarThemes = {
  default: {
    backgroundColor: 'offWhite',
    boxShadow: true,
    linkColor: 'black',
    logoType: 'blackLetters',
  },
  transparent: {
    backgroundColor: 'transparent',
    boxShadow: false,
    linkColor: 'white',
    logoType: 'whiteLetters',
  },
};

/*
 * Ported from SASS in v1 of www.gloveworx.com
 * See: https://github.com/owlmountainproducts/gloveworxcom/blob/4365882415d17b82819741af3f3515f9d5c14b1c/website/src/sass/organisms/_navbar.scss
 */
export const gloveworxNavbar = (backgroundColor, boxShadow) => {
  const boxShadowStyling = () => {
    if (boxShadow) {
      return 'box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);';
    } else {
      return null;
    }
  };
  return css`
    ${bgColor[backgroundColor]};
    position: fixed;
    top: 0;
    left: 0;
    // for explanation of z-index value, see:
    // https://github.com/owlmountainproducts/gloveworxcom/pull/56#discussion_r140418498
    z-index: 19;
    min-height: 3.25rem;
    ${boxShadowStyling()}
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    transition: all 0.5s ease-in-out;

    ${mq.desktop} {
      flex-wrap: nowrap;
    }
  `;
};

export const navbarLogoWrapper = css`
  display: none;
  ${mq.tablet} {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    a {
      width: 100px;
      background-size: auto 60%;
    }
    padding-right: ${spacing.margin.mobile};
  }
`;

let logoStyling = css`
  max-height: 100px;
  margin-left: 1rem;
  ${mq.desktop} {
    margin: 1rem auto;
  }
  grid-column: span 3;
`;

export const navbarItem = linkColor => {
  return css`
    transition: all 0.35s ease-in-out;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-decoration: none;
    text-align: left;
    font-weight: 500;
    ${textColor['black']}

    ${mq.tablet} {
      font-size: 1rem;
      padding: 1rem 0.75rem;
      margin-top: auto;
      margin-bottom: auto;
      ${textColor[linkColor]}
      &:hover {
        ${textColor.red};
      }
    }

    ${mq.desktop} {
      font-size: 1.25rem;
      padding: 1rem 1.5rem;
    }
  `;
};

export const navbarMenu = css`
  ${menuText};
  justify-content: space-around;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  width: 100%;
  max-height: 0;
  padding: 0;
  text-align: left;
  display: flex;
  color: black;
  ${mq.tablet} {
    max-height: inherit;
    width: auto;
    justify-content: space-around;
  }
`;

export const navbarBookNowWrapper = css`
  display: flex;
  flex-grow: 0;
  margin-right: 1rem;
  height: $navbar-height;

  ${textColor.black}
  ${standardPaddingMixin('right')};

  ${mq.tablet} {
    margin-right: 0;
    height: auto;
    padding-right: 1.125rem;
  }
}
`;
/**
 * This styled implementation is based on the comments from:
 * https://github.com/gatsbyjs/gatsby/issues/7822#issuecomment-422825054
 */
const LogoBolt = styled(Img)`
  position: relative;
  order: 1;
  width: 100%;
  max-height: 3rem;
  max-width: 4.5rem;
  margin: 0.5rem 1rem;
  object-fit: contain;
  ${mq.tablet} {
    display: none;
  }
`;

class Navbar extends Component {
  static propTypes = {
    theme: PropTypes.oneOf(['default', 'transparent']),
  };

  static defaultProps = {
    theme: 'default',
  };

  constructor(props) {
    super(props);
    this.state = { isToggleOn: false };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }

  renderMenuItems(items, linkColor) {
    return items.map(item =>
      item.target.indexOf('http') === -1 ? (
        <Link
          key={`menu-item-${item.name}`}
          css={navbarItem(linkColor)}
          to={item.target}
        >
          {item.name}
        </Link>
      ) : (
        <a
          key={`menu-item-${item.name}`}
          href={item.target}
          css={navbarItem(linkColor)}
        >
          {item.name}
        </a>
      )
    );
  }

  render() {
    const { theme } = this.props;
    const activeTheme = navbarThemes[theme];
    const menuItems = [
      { name: 'SESSIONS', target: 'https://www.gloveworx.com/sessions/core/' },
      { name: 'COACHES', target: 'https://www.gloveworx.com/coaches/' },
      { name: 'LOCATIONS', target: 'https://www.gloveworx.com/locations/' },
      { name: 'BLOG', target: 'https://www.gloveworx.com/blog/' },
      { name: 'SHOP', target: 'https://shop.gloveworx.com' },
    ];

    /**
     * https://github.com/negomi/react-burger-menu#javascript
     * all the stylings need to be provided, can be found in the link above
     * they are already as `style={}` we need to replace them as css``
     *
     */

    return (
      <StaticQuery
        query={graphql`
          query {
            gwxLogoBolt: file(name: { eq: "gwx-logo-bolt.original" }) {
              childImageSharp {
                fluid(maxWidth: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            gwxWhiteLogo: file(name: { eq: "gwx-logo-white.original" }) {
              childImageSharp {
                fluid(maxWidth: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => (
          <nav
            css={gloveworxNavbar(activeTheme.backgroundColor)}
            id="gloveworx-navbar"
          >
            <Hamburger
              menuItems={this.renderMenuItems(menuItems, activeTheme.linkColor)}
              isToggleOn={this.state.isToggleOn}
              theme={theme}
            />

            {theme === 'default' ? (
              <LogoBolt fluid={data.gwxLogoBolt.childImageSharp.fluid} />
            ) : (
              <LogoBolt fluid={data.gwxWhiteLogo.childImageSharp.fluid} />
            )}

            <div css={navbarLogoWrapper}>
              <a href="https://www.gloveworx.com/">
                <Logo
                  logoStyling={logoStyling}
                  logoType={activeTheme.logoType}
                />
              </a>
            </div>
            <div css={navbarMenu}>
              {this.renderMenuItems(menuItems, activeTheme.linkColor)}
            </div>
          </nav>
        )}
      />
    );
  }
}

export default Navbar;
